export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(authenticated)/(root)": [~5,[2]],
		"/(authenticated)/contacts": [~6,[2]],
		"/(unauthenticated)/login/(root)": [~10,[3]],
		"/(unauthenticated)/login/change": [~11,[3]],
		"/(unauthenticated)/login/recover": [~12,[3]],
		"/(unauthenticated)/login/[slug]": [~13,[3]],
		"/(authenticated)/property/[parcel_id]": [~9,[2]],
		"/(authenticated)/settings": [~4,[2]],
		"/(authenticated)/t": [7,[2]],
		"/(authenticated)/[slug]": [~8,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';